export const mockData = {
  s_geography: {
    rid: "ri.object-set.main.temporary-object-set.764c13bd-9f10-4205-b9b3-1ec7796076e5",
    objectRids: [
      "ri.phonograph2-objects.main.object.9196afa4-3ac9-432b-8e92-2cb97a159510",
      "ri.phonograph2-objects.main.object.f24c95a6-e37e-4327-8009-a6852ebd9fa3",
      "ri.phonograph2-objects.main.object.11b2ae4c-647e-4673-8801-ed8b6ce1d681",
      "ri.phonograph2-objects.main.object.cd7761be-2233-4448-b77d-f835753a0c48",
      "ri.phonograph2-objects.main.object.209f457c-c325-4e8a-b3f0-02c4dba36880",
      "ri.phonograph2-objects.main.object.3df37398-2936-4bc6-b266-56242a91a69c",
      "ri.phonograph2-objects.main.object.d36b4f59-f68a-42c4-884a-d43ec31b12ea",
      "ri.phonograph2-objects.main.object.3e51bb69-9da5-422a-890f-9df454b8a622",
      "ri.phonograph2-objects.main.object.f2f704c3-a862-4540-b40f-b7fcba5df87d",
      "ri.phonograph2-objects.main.object.793c2126-87ea-419e-8e6b-efe4c4ce3835",
      "ri.phonograph2-objects.main.object.4b18585d-84cb-4c58-8094-ea05de171569",
      "ri.phonograph2-objects.main.object.ac744727-5f55-48c8-90ed-38591cec67ae",
      "ri.phonograph2-objects.main.object.0181eb5c-f6ac-4f9b-91c0-ca97936ee809",
      "ri.phonograph2-objects.main.object.a370cbcb-5369-4e18-9869-54bc7da53edb",
      "ri.phonograph2-objects.main.object.e91aacb7-aba0-4baf-bcb1-7653ab22a7cd",
    ],
    data: {
      type: [
        "geography",
        "geography",
        "geography",
        "geography",
        "geography",
        "geography",
        "geography",
        "geography",
        "geography",
        "geography",
        "geography",
        "geography",
        "geography",
        "geography",
        "geography",
      ],
      modified_at: [
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
      ],
      created_at: [
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
      ],
      id: [
        "DK",
        "EE",
        "EI",
        "EM",
        "EN",
        "EW",
        "GB",
        "LB",
        "SC",
        "WS",
        "byggefakta_22",
        "byggefakta_23",
        "byggefakta_24",
        "byggefakta_25",
        "byggefakta_26",
      ],
      modified_by: [
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
      ],
      parent_id: [
        "",
        "GB",
        "GB",
        "GB",
        "GB",
        "GB",
        "",
        "GB",
        "GB",
        "GB",
        "DK",
        "DK",
        "DK",
        "DK",
        "DK",
      ],
      name: [
        "Denmark",
        "England (south east)",
        "Ireland",
        "England (midlands and east)",
        "England (north)",
        "England (south and west)",
        "United Kingdom",
        "London boroughs",
        "Scotland",
        "Wales",
        "Hovedstaden",
        "Midtjylland",
        "Nordjylland",
        "Sjælland",
        "Syddanmark",
      ],
      created_by: [
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
      ],
    },
    totalObjects: 15,
    pagingToken: null,
    aggregations: {},
  },
};
