import React, { useState, useEffect } from "react";
import { createRoot } from "react-dom/client";
import { Button, Select } from "./Components";
import { OntologyUtils } from "./Common";
import { mockData } from "./constants";
const root = createRoot(document.getElementById("root"));
root.render(<App />);

declare global {
  interface Window {
    stratosData: any;
  }
}

function App() {
  const [data, dataProvider] = useState(null);

  useEffect(() => {
    // @ts-expect-error
    window.dataProvider = dataProvider;
    console.log("data in is:", data);
    if (!window.location.host.includes("palantir")) {
      dataProvider(mockData);
    }
  }, []);

  useEffect(() => {
    window.stratosData = data;
    console.log("data out is:", data);
  }, [data]);

  if (!data) return <p>Loading</p>;

  const mappedOptions = OntologyUtils.convertObjectSetToObjectArray(
    ["id", "name"],
    data.s_geography
  ).map((v) => {
    return { value: v.id, label: v.name };
  });

  return (
    <>
      <h1>React app</h1>
      <Select options={mappedOptions}></Select>
    </>
  );
}
