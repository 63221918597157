import React, { useState } from "react";

export const Button = () => {
  return <button className="stratos-btn"></button>;
};

export const Select = ({ options }) => {
  return (
    <select className="stratos-select">
      {options.map((option, i) => (
        <option value={option.value} key={i}>
          {option.label}
        </option>
      ))}
    </select>
  );
};
