type OntologyObjectSet<ColumnIds extends string> = {
    rid: string,
    objectRids: Array<string>,
    data: { [key in ColumnIds]: Array<string> },
    totalObjects: number,
    pagingTOken: null | string,
    aggregations: Array<any>
}

export const OntologyUtils = {
    convertObjectSetToObjectArray<T extends string>(columnIds: Array<string>, dataset: OntologyObjectSet<T>) {
        const arr = []
        for (let i = 0; i < dataset.totalObjects; i++) {
            const row = {}
            columnIds.forEach(columnId=> row[columnId] = dataset.data[columnId][i])
            arr.push(row)
        }
        return arr
    },
    
}